<template>
  <div class="installment-program">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" right v-show="false">
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="excelModal">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <router-link to="/installment-program/add-installment-program">
              <b-button variant="primary" size="sm" class="font-weight-bolder">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row>
              <b-col class="pl-0">
                <b-input
                  size="sm"
                  placeholder="Nhập tên hoặc mã chương trình"
                  v-model="apiParams.name"
                  v-on:input="fetchInstallmentProgram()"
                ></b-input>
              </b-col>

              <b-col> </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="installmentPrograms"
          :fields="installmentProgramFields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(statusName)="row">
            <span
              v-text="row.item.statusName"
              class="label font-weight-bold label-lg label-inline"
              :class="
                row.item.status === true
                  ? 'label-light-success'
                  : 'label-light-danger'
              "
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('PRODUCT_ATTACH_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-show="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchInstallmentProgram()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
// import moment from 'moment';
import { TIME_OUT } from '../../../utils/constants';

// import axios from 'axios';
// import { BASE_URL } from '../../../utils/constants';
import { makeToastFaile, makeToastSuccess } from './../../../utils/common';

export default {
  data() {
    return {
      title: '',
      apiParams: {
        name: null,
      },
      installmentPrograms: [],
      installments: [],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      installmentProgramFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.title = 'Danh sách chương trình trả góp';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.fetchInstallmentProgram();
  },
  methods: {
    editItem: function (item) {
      this.$router.push({
        name: 'edit-installment-program',
        params: { id: item.id },
      });
    },
    getParamFilters() {
      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        name: this.apiParams.name ? this.apiParams.name.trim() : null,
      };
    },
    fetchInstallmentProgram: async function () {
      this.page = this.$route.query.page || 1;

      const params = this.getParamFilters();

      ApiService.query(
        '/installment-programs',
        { params },
        { timeout: TIME_OUT }
      ).then((response) => {
        const dataset = response.data.data;
        this.totalItems = dataset.total;
        this.numberOfPage = dataset.total_page;
        this.installmentPrograms = dataset.data;
        this.onLoading = false;
      });
    },
    showDeleteSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Xóa chương trình trả góp thành công!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    deleteInstallmentProgram: async function (item) {
      ApiService.delete('installment-programs' + '/' + item.id)
        .then((data) => {
          const { status, message } = data.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchInstallmentProgram();
          } else {
            makeToastFaile(message);
          }
        })
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa chương trình trả góp!',
        text: 'Bạn có chắc muốn xóa chương trình trả góp này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteInstallmentProgram(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    excelModal: async function () {
      alert('Tính năng đang phát triển');
    },
    debounceInput: decounce(function () {
      this.searchEmployee();
    }, 2000),
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.installment-program {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }
}
</style>
